var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BaseLayout', {
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_c('v-icon', {
          attrs: {
            "left": ""
          },
          on: {
            "click": _vm.navigateBack
          }
        }, [_vm._v(" mdi-arrow-left")]), _vm._v("Fremmøte - " + _vm._s(_vm.formatLocalizedDate(_vm.$route.params.scheduleStart)) + " (" + _vm._s(_vm.courseId) + ")")];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function () {
        return [_c('v-text-field', {
          attrs: {
            "append-icon": "mdi-magnify",
            "label": "Søk",
            "hide-details": ""
          },
          model: {
            value: _vm.search,
            callback: function ($$v) {
              _vm.search = $$v;
            },
            expression: "search"
          }
        }), _vm.showRegisterButton ? _c('v-btn', {
          staticClass: "ma-2",
          attrs: {
            "data-cy": "registerAttendance",
            "color": "white",
            "small": "",
            "outlined": ""
          },
          on: {
            "click": _vm.batchRegisterAttendance
          }
        }, [_vm._v("Registrer fremmøte")]) : _vm._e()];
      },
      proxy: true
    }])
  }, [_vm.modalData.showModal ? _c('BaseModal', [_c('TheCourseAttendanceParticipantModal', {
    attrs: {
      "headline": _vm.modalData.modalHeadline,
      "modalType": _vm.modalData.modalType,
      "attendanceParticipant": _vm.modalData.existingItem
    },
    on: {
      "close": function ($event) {
        _vm.modalData.showModal = false;
      },
      "updateCourseParticipant": _vm.registerSingleAttendance
    }
  })], 1) : _vm._e(), _c('v-data-table', {
    attrs: {
      "headers": _vm.headers,
      "items": _vm.attendanceParticipants,
      "search": _vm.search,
      "show-select": "",
      "data-cy": "attendanceParticipantTable",
      "item-key": "userId"
    },
    scopedSlots: _vm._u([{
      key: "item.noOfHours",
      fn: function (_ref) {
        var item = _ref.item;
        return [_vm._v(" " + _vm._s(_vm.noOfHoursText(item)) + " ")];
      }
    }, {
      key: "item.isRegistered",
      fn: function (_ref2) {
        var value = _ref2.value;
        return [_vm._v(" " + _vm._s(value ? "Registrert" : "Ikke registrert") + " ")];
      }
    }, {
      key: "item.updatedByUserFullName",
      fn: function (_ref3) {
        var value = _ref3.value;
        return [_vm._v(" " + _vm._s(value || "-") + " ")];
      }
    }, {
      key: "item.updated",
      fn: function (_ref4) {
        var value = _ref4.value;
        return [_vm._v(" " + _vm._s(value ? _vm.formatDate(value) : "-") + " ")];
      }
    }, {
      key: "item.actions",
      fn: function (_ref5) {
        var item = _ref5.item;
        return [_c('v-btn', {
          attrs: {
            "icon": "",
            "color": "primary",
            "data-cy": "editAttendanceParticipant"
          },
          on: {
            "click": function ($event) {
              return _vm.editAttendanceParticipant(item);
            }
          }
        }, [_c('v-icon', [_vm._v("mdi-pencil")])], 1)];
      }
    }], null, true),
    model: {
      value: _vm.selectedAttendanceParticipants,
      callback: function ($$v) {
        _vm.selectedAttendanceParticipants = $$v;
      },
      expression: "selectedAttendanceParticipants"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }