
import { api } from "@/api/api";
import { ApiGetCourseParticipantAttendanceDto } from "@/api/generated/Api";
import TheCourseAttendanceParticipantList from "@/components/courses/list/TheCourseAttendanceParticipantList.vue";
import BaseBreadCrumbs from "@/components/shared/breadcrumbs/BaseBreadCrumbs.vue";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { useRoute } from "@/shared/useHelpers";
import { defineComponent, onMounted, ref } from "@vue/composition-api";

export default defineComponent({
  name: "courseAttendanceParticipant",
  components: {
    BaseBreadCrumbs,
    TheCourseAttendanceParticipantList,
  },
  setup() {
    const route = useRoute();
    const attendanceParticipants = ref<ApiGetCourseParticipantAttendanceDto[]>();
    const courseId = +route.params.courseId;
    const scheduleStart = route.params.scheduleStart || undefined;

    const loadAttendanceParticipants = async () => {
      globalLoadingWrapper({ blocking: true }, async () => {
        const courseParticipants = (
          await api.course.getCourseParticipantAttendancesByCourseIdAsync(courseId, {
            ScheduleStart: scheduleStart,
          })
        ).data;
        attendanceParticipants.value = courseParticipants.map((participant) => ({
          ...participant,
          isSelectable: !participant.isRegistered,
        }));
      });
    };
    onMounted(async () => {
      loadAttendanceParticipants();
    });
    return {
      attendanceParticipants,
      loadAttendanceParticipants,
    };
  },
});
