var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BaseModalForm', {
    attrs: {
      "headline": ((_vm.headline) + " " + (_vm.attendanceParticipant.userFullName)),
      "modalType": _vm.modalType
    },
    on: {
      "close": function ($event) {
        return _vm.$emit('close');
      },
      "submit": _vm.handleSubmit
    }
  }, [_c('v-form', {
    ref: "form",
    attrs: {
      "lazy-validation": ""
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "data-cy": "editAttendanceHours",
      "name": "registeredHours",
      "type": "number",
      "step": "0.5",
      "label": "Timer",
      "rules": _vm.validateCourseHourInput(0, _vm.attendanceInput.scheduleHours, true)
    },
    model: {
      value: _vm.attendanceInput.noOfHours,
      callback: function ($$v) {
        _vm.$set(_vm.attendanceInput, "noOfHours", $$v);
      },
      expression: "attendanceInput.noOfHours"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "data-cy": "editAttendanceComment",
      "name": "comment",
      "type": "text",
      "label": "Kommentar"
    },
    model: {
      value: _vm.attendanceInput.comment,
      callback: function ($$v) {
        _vm.$set(_vm.attendanceInput, "comment", $$v);
      },
      expression: "attendanceInput.comment"
    }
  })], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }