var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    attrs: {
      "fluid": ""
    }
  }, [_vm.attendanceParticipants ? _c('TheCourseAttendanceParticipantList', {
    attrs: {
      "attendanceParticipants": _vm.attendanceParticipants
    },
    on: {
      "reloadAttendanceParticipants": _vm.loadAttendanceParticipants
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }