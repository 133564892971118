
import { api } from "@/api/api";
import {
  ApiCourseParticipantAttendanceBaseDtoValidationResponseDto,
  ApiGetCourseParticipantAttendanceDto,
} from "@/api/generated/Api";
import TheCourseAttendanceParticipantModal from "@/components/courses/section/TheCourseAttendanceParticipantModal.vue";
import BaseLayout from "@/components/shared/layout/BaseLayout.vue";
import BaseModal from "@/components/shared/modal/BaseModal.vue";
import { ModalType } from "@/shared/enums/modalTypeEnum";
import { NotificationItemType } from "@/shared/enums/notificationItemEnum";
import { formatLocalizedDate, formatDate } from "@/shared/helpers/dateHelpers";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { getInitialModalData, useOpenModal } from "@/shared/helpers/modalHelpers";
import { useNavigateBack } from "@/shared/helpers/navigationHelpers";
import { openNotification } from "@/shared/helpers/store.helpers";
import { useRouter, useStore } from "@/shared/useHelpers";
import { StoreState } from "@/store/store.state.interface";
import { computed, defineComponent, PropType, ref } from "@vue/composition-api";
import axios from "axios";

export default defineComponent({
  name: "TheCourseAttendanceParticipantList",
  components: {
    BaseModal,
    TheCourseAttendanceParticipantModal,
    BaseLayout,
  },
  props: {
    attendanceParticipants: {
      type: Array as PropType<ApiGetCourseParticipantAttendanceDto[]>,
      required: true,
    },
  },
  emits: ["reloadAttendanceParticipants"],
  setup(props, { emit }) {
    const store = useStore<StoreState>();
    const router = useRouter();
    const search = ref("");
    const selectedAttendanceParticipants = ref<ApiGetCourseParticipantAttendanceDto[]>();
    const showRegisterButton = computed(() => selectedAttendanceParticipants.value?.length);
    const courseId = router.currentRoute.params.courseId;
    const modalData = ref(getInitialModalData());

    const navigateBack = () => {
      useNavigateBack("singleAssignmentCourse", { id: courseId, userStatus: "påmeldt", tab: "attendance" });
    };

    const noOfHoursText = (participantAttendance: ApiGetCourseParticipantAttendanceDto) => {
      if (!participantAttendance.isRegistered) {
        participantAttendance.noOfHours || "Avventer registrering";
      }
      return participantAttendance.noOfHours || "Ikke møtt";
    };

    const editAttendanceParticipant = useOpenModal(ModalType.Edit, "fremmøte for: ", modalData);

    const batchRegisterAttendance = async () => {
      if (!selectedAttendanceParticipants.value) {
        return;
      }
      selectedAttendanceParticipants.value.map((participant) => (participant.noOfHours = participant.scheduleHours));
      await updateCourseParticipantAttendance(selectedAttendanceParticipants.value);
      selectedAttendanceParticipants.value = [];
    };

    const registerSingleAttendance = async (attendanceEntry: ApiGetCourseParticipantAttendanceDto) => {
      await updateCourseParticipantAttendance([attendanceEntry]);
    };

    const updateCourseParticipantAttendance = async (attendances: ApiGetCourseParticipantAttendanceDto[]) => {
      globalLoadingWrapper({ blocking: true }, async () => {
        try {
          await api.course.batchMergeCourseParticipantAttendancesByCourseIdAsync(+courseId, { attendances });
          openNotification(store, NotificationItemType.Success, "Fremmøte er registrert", 4000);
          emit("reloadAttendanceParticipants");
        } catch (error: unknown) {
          if (!axios.isAxiosError(error) || !error.response || !isValidationErrorResponse(error.response?.data)) {
            return;
          }
          const { validationList } = error.response?.data;
          validationList?.forEach((item) => {
            item.validationErrors?.forEach((message) => {
              openNotification(store, NotificationItemType.Warning, `Valideringsfeil: ${message}`, 8000);
            });
          });
        }
      });
    };

    return {
      courseId,
      headers,
      search,
      modalData,
      navigateBack,
      editAttendanceParticipant,
      noOfHoursText,
      selectedAttendanceParticipants,
      showRegisterButton,
      batchRegisterAttendance,
      registerSingleAttendance,
      formatLocalizedDate,
      formatDate,
    };
  },
});

const headers = [
  {
    text: "Navn",
    value: "userFullName",
  },
  {
    text: "Fremmøte registrert",
    value: "noOfHours",
  },
  {
    text: "Antall timer i timeplan",
    value: "scheduleHours",
  },
  {
    text: "Status",
    value: "isRegistered",
  },
  {
    text: "Oppdatert av",
    value: "updatedByUserFullName",
  },
  {
    text: "Sist oppdatert",
    value: "updated",
  },
  {
    text: "Handlinger",
    value: "actions",
  },
];

const isValidationErrorResponse = (
  response: unknown
): response is ApiCourseParticipantAttendanceBaseDtoValidationResponseDto =>
  typeof response === "object" &&
  response !== null &&
  ("validationList" in response || "generalValidationErrors" in response);
